import axios from 'axios';
let url = window.location.href

export const httpClientIdentity = 
axios.create({
  baseURL: url.includes('localhost') ? 'https://wolfwolf.testmedici.com/api/sirix' : `${window.location.host}/api/sirix`,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const httpClient = axios.create({
  baseURL: url, //YOUR_API_URL HERE
  headers: {
    'Content-Type': 'application/json',
  },
});
