import React, { useState } from "react";
import { Button, Checkbox, Form, Input, Modal } from "antd";
import { Link } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import { useAuth } from "../authentication";
import AppNotificationContainer from "../components/AppNotificationContainer";
import background from "../assets/images/wolfmarkets-sca-bg.jpg";
import AppNotificationContainerSıgnIn from "../components/AppNotificationContainerSıgnIn";
import CircularProgressSignIn from "../components/CircularProgressSignIn";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

const SignIn = () => {
	const { isLoading, error, userLogin } = useAuth();

	const onFinishFailed = (errorInfo) => {};

	const onFinish = (values) => {
		userLogin(values);
	};
	const [isModalOpen, setIsModalOpen] = useState(false);
	const showModal = () => {
		setIsModalOpen(true);
	};
	const handleOk = (values) => {
		// setIsModalOpen(false);
		console.log("calisti");
		console.log(values);
	};
	const handleCancel = () => {
		setIsModalOpen(false);
	};

	return (
		<div
			className="gx-app-login-wrap"
			style={{
				backgroundImage: `url(${background})`,
				backgroundSize: "cover",
				backgroundRepeat: "no-repeat",
				backgroundPosition: "center right",
			}}
		>
			<div className="gx-app-login-container">
				<div className="gx-app-login-main-content">
					<div className="gx-app-login-content">
						<AppNotificationContainerSıgnIn loading={isLoading} error={error} />
						{/* <CircularProgressSignIn/> */}
						<Form
							initialValues={{ remember: true }}
							name="basic"
							labelCol={{ span: 8 }}
							wrapperCol={{ span: 20 }}
							onFinish={onFinish}
							onFinishFailed={onFinishFailed}
							className="gx-signin-form gx-form-row0"
						>
							<Form.Item
								className="signin-input"
								style={{ color: "white" }}
								initialValue=""
								label="Kullanıcı ID"
								rules={[
									{ required: true, message: "Lütfen Kullanıcı Id Giriniz" },
								]}
								name="userId"
							>
								<Input placeholder="Kullanıcı ID" />
							</Form.Item>
							<Form.Item
								className="signin-input"
								initialValue=""
								label="Parola"
								rules={[
									{ required: true, message: "Lütfen Parolanızı Giriniz" },
								]}
								name="password"
							>
								<Input type="password" placeholder="Parola" />
							</Form.Item>
							{/* <Form.Item>
                <Checkbox><IntlMessages id="appModule.iAccept"/></Checkbox>
                <span className="gx-signup-form-forgot gx-link"><IntlMessages
                  id="appModule.termAndCondition"/></span>
              </Form.Item> */}
							<Form.Item style={{ display: "flex", justifyContent: "center" }}>
								<Button
									type="primary"
									className="gx-mb-0-secondary"
									htmlType="submit"
								>
									<span style={{ fontWeight: "bolder", color: "#FFFFF" }}>
										<IntlMessages id="app.userAuth.signIn" />
									</span>
								</Button>
							</Form.Item>
							{/* <Button onClick={showModal}>Şifremi Unuttum</Button>
							<Modal
								title="Şifremi Unuttum"
								open={isModalOpen}
								onOk={handleOk}
								onCancel={handleCancel}
							>
								<Form
									name="basic"
									onFinish={handleOk}
									className="gx-signin-form gx-form-row0"
								>
									<Form.Item
										className="signin-input"
										style={{ color: "white" }}
										initialValue=""
										label="Kullanıcı ID"
										rules={[
											{
												required: true,
												message: "Lütfen Kullanıcı Id Giriniz",
											},
										]}
										name="userId"
									>
										<Input placeholder="Kullanıcı ID" />
									</Form.Item>
								</Form>
							</Modal> */}
							{/* <span
                className="gx-text-light gx-fs-sm"> demo user email: 'demo@example.com' and password: 'demo#123'</span> */}
						</Form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SignIn;
