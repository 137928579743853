import React from "react";
import {Route, Switch} from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({match}) => (
  <div className="gx-main-content-wrapper" color="#3399FF">
    <Switch>
      <Route path={`${match.url}sample`} component={asyncComponent(() => import('./SamplePage'))}/>
      <Route path={`${match.url}profile`} component={asyncComponent(() => import('../pages/Profile'))}/>
      <Route path={`${match.url}deposit`} component={asyncComponent(() => import('../pages/Deposit'))}/>
      <Route path={`${match.url}withdrawal`} component={asyncComponent(() => import('../pages/Withdrawal'))}/>
      <Route path={`${match.url}webtrader`} component={asyncComponent(() => import('../pages/WebTrader'))}/>
      <Route path={`${match.url}documents`} component={asyncComponent(() => import('../pages/Documents'))}/>
    </Switch>
  </div>
);

export default App;
