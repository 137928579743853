import { useEffect, useState } from 'react';
import { httpClient, httpClientIdentity } from "../../../util/Api";
import { BaseService } from '../../../services/BaseService';
import axios from 'axios';
import { toast } from 'react-toastify';

export const useProvideAuth = () => {
  const [authUser, setAuthUser] = useState(null);
  const [error, setError] = useState('');
  const [isLoadingUser, setLoadingUser] = useState(true);
  const [isLoading, setLoading] = useState(false);

  const fetchStart = () => {
    setLoading(true);
    // setError('');
  };

  const fetchSuccess = () => {
    setLoading(false);
    setError('');
  };

  const fetchError = (error) => {
    setLoading(false);
    toast.error(error)
    // setError(error);
  };

  const userLogin = async (user, callbackFun) => {
    fetchStart();
    BaseService
      .postForAdmin(`/api/sirix/login?userId=${user.userId}&password=${user.password}`, user)
      .then(res => {
        if (res.data?.Success) {
          fetchSuccess();
          // httpClientIdentity.defaults.headers.common['Authorization'] = data.Message;
          localStorage.setItem('token', res.data.Message);
          getAuthUser(res.data.Message);
          if (callbackFun) callbackFun();
        } else {
          console.log(res.response.status)
          // fetchError(data.error);
          if (res.response.data.Message === 'Invalid Credentials!!!') {
            fetchError('Hatalı Kullanıcı Id veya Parola')
          }
          else if (res.response.status === 500 || 501 || 502 || 503) {
            fetchError('Bir Hata Oluştu Lütfen Tekrar Deneyin')
  
          }
        }
      })
     
  };

  const userSignup = (user, callbackFun) => {
    fetchStart();
    httpClient
      .post('auth/register', user)
      .then(({ data }) => {
        if (data.result) {
          fetchSuccess();
          localStorage.setItem('token', data.token.access_token);
          httpClient.defaults.headers.common['Authorization'] = 'Bearer ' + data.token.access_token;
          getAuthUser();
          if (callbackFun) callbackFun();
        } else {
          fetchError(data.error);
        }
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  const sendPasswordResetEmail = (email, callbackFun) => {
    fetchStart();

    setTimeout(() => {
      fetchSuccess();
      if (callbackFun) callbackFun();
    }, 300);
  };

  const confirmPasswordReset = (code, password, callbackFun) => {
    fetchStart();

    setTimeout(() => {
      fetchSuccess();
      if (callbackFun) callbackFun();
    }, 300);
  };

  const renderSocialMediaLogin = () => null;

  const userSignOut = (callbackFun) => {
    fetchSuccess();
    httpClient.defaults.headers.common['Authorization'] = '';
    localStorage.removeItem('token');
    setAuthUser(false);
    if (callbackFun) callbackFun();
    // fetchStart();
    // httpClient
    //   .post('auth/logout')
    //   .then(({ data }) => {
    //     if (data.result) {
    //       fetchSuccess();
    //       httpClient.defaults.headers.common['Authorization'] = '';
    //       localStorage.removeItem('token');
    //       setAuthUser(false);
    //       if (callbackFun) callbackFun();
    //     } else {
    //       fetchError(data.error);
    //     }
    //   })
    //   .catch(function (error) {
    //     fetchError(error.message);
    //   });
  };

  const getAuthUser = (...token) => {
    fetchStart();
    BaseService
      .getForAdmin('/api/sirix/GetMe', token)
      .then(({ data }) => {
        if (data.UserId) {
          fetchSuccess();
          setAuthUser(data);
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        } else {
          fetchError(data.error);
        }
      })
      .catch(function (error) {
        httpClient.defaults.headers.common['Authorization'] = '';
        fetchError(error.message);
      });
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (localStorage.getItem('token')) {
      BaseService
        .getForAdmin('/api/sirix/GetMe', token)
        .then(({ data }) => {
          if (data) {
            setAuthUser(data);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
          }
          setLoadingUser(false);
        })
        .catch(function () {
          localStorage.removeItem('token');
          httpClient.defaults.headers.common['Authorization'] = '';
          setLoadingUser(false);
        });
    } else {
      localStorage.removeItem('token');
      httpClient.defaults.headers.common['Authorization'] = '';
      setLoadingUser(false)
    }
  }, []);

  // Return the user object and auth methods
  return {
    isLoadingUser,
    isLoading,
    authUser,
    error,
    setError,
    setAuthUser,
    getAuthUser,
    userLogin,
    userSignup,
    userSignOut,
    renderSocialMediaLogin,
    sendPasswordResetEmail,
    confirmPasswordReset,
  };
};
