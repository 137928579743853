import React, { useState, useEffect } from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { useSelector } from "react-redux";
import googleplay from '../../assets/images/googleplay.png'
import appstore from '../../assets/images/appstore.png'

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const [app, setApp] = useState({
    ios: "",
    android: ""
  })
  const { navStyle, themeType } = useSelector(({ settings }) => settings);
  const pathname = useSelector(({ common }) => common.pathname);

  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];

  useEffect(() => {
    getApp()
  }, [])

  const getApp = () => {
    const location = window.location.origin
    let url;
    if (location.includes("localhost") || location.includes("test")) {
      url = "https://wolf.testmedici.com/api/Global/Apps"
    } else {
      url = `${location}/api/Global/Apps`
    }

    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }
        return response.json();
      })
      .then(data => {
        setApp({
          ios: data.ios,
          android: data.android
        })
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }

  return (
    <>
      <SidebarLogo sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} />
      <div className="gx-sidebar-content">
        <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
          <UserProfile />
          {/* <AppsNavigation/> */}
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline">

            <Menu.Item key="profile">
              <Link to="/profile"><i className="icon icon-user" />
                <span><IntlMessages id="sidebar.profilePage" /></span>
              </Link>
            </Menu.Item>
            <Menu.Item key="deposit">
              <Link to="/deposit"><i className="icon icon-chevron-right" />
                <span><IntlMessages id="sidebar.deposit" /></span>
              </Link>
            </Menu.Item>
            <Menu.Item key="withdrawal">
              <Link to="/withdrawal"><i class="icon icon-chevron-left"></i>
                <span><IntlMessages id="sidebar.withdrawal" /></span>
              </Link>
            </Menu.Item>
            <Menu.Item key="webtrader">
              <Link to="/webtrader"><i className="icon icon-data-display" />
                <span><IntlMessages id="sidebar.webtrader" /></span>
              </Link>
            </Menu.Item>
            <Menu.Item key="documents">
              <Link to="/documents"><i className="icon icon-files" />
                <span><IntlMessages id="sidebar.document" /></span>
              </Link>
            </Menu.Item>
          </Menu>


          <div style={{ display: 'flex', justifyContent: 'space-around', position: 'absolute', bottom: 40 }}>
            <Link style={{ width: '40%' }} to={{ pathname: app.android }} target='_blank' ><img src={googleplay} /></Link>
            <Link style={{ width: '40%' }} to={{ pathname: app.ios }} target='_blank' ><img src={appstore} /></Link>
          </div>
        </CustomScrollbars>
      </div>
    </>
  );
};

export default React.memo(SidebarContent);

