import axios from "axios"

let baseUrl;
let adminUrl
if (window.location.origin.includes('localhost')) {
    baseUrl = 'https://scawolf.testmedici.com'
    adminUrl = 'https://wolfwolf.testmedici.com'
}
else {  //canlı domain
    baseUrl = window.location.origin
    adminUrl = window.location.origin.replace('sca', 'wolf')
}


export const BaseService = {
    post: async (url, data) => {
        let response;
        await axios.post(baseUrl + url, data)
            .then(res => response = res)
            .catch(error => response = error)
        return response
    },
    postForAdmin: async (url, data) => {
        let response;
        await axios.post(adminUrl + url, data)
            .then(res => response = res)
            .catch(error => response = error)
        return response
    },
    get: async (url) => {
        let response;
        await axios.get(baseUrl + url)
            .then(res => response = res)
            .catch(error => response = error)
        return response
    },
    getForAdmin: async (url) => {
        let response;
        let headers
        if(localStorage.getItem('token')){
            headers = { 'Authorization': localStorage.getItem('token') }
        }
        await axios.get(adminUrl + url, {headers})
            .then(res => response = res)
            .catch(error =>response = error)
        return response
    }
}
